import Link from 'next/link';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import {
	Footer,
	MainHeader,
	MainWrapper,
	PageFullWrapper,
	SpecialistsMap,
	SpecialistCard,
	HomeForm
} from 'components/front';
import {
	CommonServerSideProps,
	getCommonServerSideProps
} from 'api-common/common-server-side-props';
import { SessionProvider } from 'common/session/session-context';
import { createRoute, RoutesEnum } from 'common/routes';
import { serializeQueryString } from 'common/url/serialize-query-string';
import { ProfessionDto } from 'entities/profession.dto';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { Button } from '../../reactstrap/Button';
import { Modal } from '../../reactstrap/Modal';
import { ModalBody } from '../../reactstrap/ModalBody';
import { ModalFooter } from '../../reactstrap/ModalFooter';
import { ModalHeader } from '../../reactstrap/ModalHeader';
import { Input } from '../../reactstrap/Input';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { BsChevronRight } from 'react-icons/bs';
import { GoChevronRight } from 'react-icons/go';
import {
	mapBooleanToQueryParam,
	QueryParamsMapEnum
} from 'common/query-params-filter-enum';
import {
	createSpecializationLinksCreator,
	filterProfessionByParentId
} from 'common/filters/create-specialization-links';
import { NoResultsBanner } from 'components/front/no-results-banner';
import Head from 'next/head';

export const getServerSideProps = getCommonServerSideProps;

const queryReturn = `
id
user {
	payment_expiration_at
}
			avatar_url
			shown_name
			description
			calendar_settings
			profession {
				id
				name
			}
			city {
				id
				name
			}
			secondary_professions_specialists {
				id
				profession {
					id
					name
				}
			}
			addressesses {
				id
				city
				street
				postal_code
				building_no
				latitude
				longitude
				calendar_settings
				is_online
        place_id
        specialist_id
			}
			calendar_settings_by_specialist_id {
				specialist_id
				calendar_settings
				addressess {
					id
					city
					street
					postal_code
					building_no
					latitude
					longitude
					is_online
          place_id
          specialist_id
				}
			}
			services {
				id
				name
				addresses
				price
				price_to
				price_negotiable
				timespan
			}
			reservations(where: { reservation_at: { _gt: $now } }) {
				id
				is_accepted
        is_phantom
				is_rejected
				reservation_at
				reservation_place
				reservation_timespan
				price
				service
        event_id
        event {
					addressess {
						id
						city
						street
						postal_code
						building_no
						latitude
						longitude
						is_online
						place_id
						specialist_id
					}
					price
					event_at
					timespan
					event_place
          address_id
          capacity
          events_users {
            id
          }
          events_users_aggregate(
            where: { is_cancelled: { _eq: false }, is_rejected: { _eq: false } }
          ) {
            aggregate {
              count
            }
          }
        }
			}
			opinions_aggregate {
				aggregate {
					avg {
						rating
					}
					count
				}
			}
`;

const ONLINE_QUERY = gql`
	query SpecialistsQuery($profession_slug: String, $now: timestamptz) {
		professions(
			where: { parent_id: { _is_null: true }, type: { _eq: "specialist" } }
		) {
			id
			name
			slug
			parent_id
			type
		}
		addressess_aggregate(
			distinct_on: city
			where: { is_online: { _eq: false } }
		) {
			nodes {
				city
			}
		}
		specialists(
			where: {
				_and: [
					{
						_or: [
							{ profession: { name: { _ilike: $profession_slug } } }
							{ shown_name: { _ilike: $profession_slug } }
							{ description: { _ilike: $profession_slug } }
							{
								secondary_professions_specialists: {
									profession: { name: { _ilike: $profession_slug } }
								}
							}
						]
					}
					{
						_or: [
							{
								calendar_settings_by_specialist_id: {
									addressess: { is_online: { _eq: true } }
								}
							}
							{ addressesses: { is_online: { _eq: true } } }
						]
					}
				]
				user: { is_activated: { _eq: true } }
			}
		) {
			${queryReturn}
		}
	}
`;

const OFFLINE_QUERY = gql`
	query SpecialistsQuery(
		$profession_slug: String
		$city_slug: String
		$now: timestamptz
	) {
		professions(where: { type: { _eq: "specialist" } }) {
			id
			name
			slug
			parent_id
			type
		}
		addressess_aggregate(
			distinct_on: city
			where: { is_online: { _eq: false } }
		) {
			nodes {
				city
			}
		}
		specialists(
			where: {
				_and: [
					{
						_or: [
							{ addressesses: { city: { _ilike: $city_slug } } }
							{
								calendar_settings_by_specialist_id: {
									addressess: { city: { _ilike: $city_slug } }
								}
							}
						]
					}
					{
						_or: [
							{ profession: { name: { _ilike: $profession_slug } } }
							{ shown_name: { _ilike: $profession_slug } }
							{ description: { _ilike: $profession_slug } }
							{
								secondary_professions_specialists: {
									profession: { name: { _ilike: $profession_slug } }
								}
							}
						]
					}
				]
				user: { is_activated: { _eq: true } }
			}
		) {
			${queryReturn}
		}
	}
`;

export const createSearchResultUrl = (
	specializationFilter: string,
	cityFilter?: string,
	onlineFilter?: boolean,
	redirect?: boolean
) => {
	return (
		createRoute(
			redirect ? RoutesEnum.Place_AddSpecialists : RoutesEnum.Front_Specialists
		) +
		'?' +
		serializeQueryString({
			[QueryParamsMapEnum.Profession]: specializationFilter,
			[QueryParamsMapEnum.City]: cityFilter || '',
			[QueryParamsMapEnum.Online]: mapBooleanToQueryParam(onlineFilter || false)
		})
	);
};

export const createPrettySearchResultUrl = (
  specializationFilter: string,
  cityFilter?: string,
  onlineFilter?: boolean,
  redirect?: boolean
) => {
  if (redirect) {
    return createSearchResultUrl(specializationFilter, cityFilter, onlineFilter, redirect);
  }
  return createRoute(RoutesEnum.Front_SpecialistsParametrized, {
    city: onlineFilter ? "online" : (cityFilter ? cityFilter.replaceAll(" ", "_") : ''),
    category: !!specializationFilter
      ? specializationFilter.replaceAll(" ", "_")
      : "wszyscy",
  });
};

export const mapSlugs = (
  slugPath: string[]
) => {
  const professionSlug = slugPath && slugPath[0] ? slugPath[0] : 'wszyscy';
  const citySlug = slugPath && slugPath[1] ? slugPath[1] : '';

  return {
    cityFilter: citySlug != 'online' ? citySlug.replaceAll('_', ' ') : '',
    professionFilter: professionSlug == 'wszyscy' ? '' : professionSlug.replaceAll('_', ' '),
    onlineFilter: citySlug == 'online'
  }
}



const SPECIALIZATIONS_ID = 'specializations';

const Specjalisci: React.FC<CommonServerSideProps> = ({ userSession }) => {
	const router = useRouter();

	if (!userSession) {
		return null;
	}

  const { cityFilter, onlineFilter, professionFilter } = mapSlugs(router.query?.slugPath as string[]);

	const { data, loading } = useQuery(
		onlineFilter ? ONLINE_QUERY : OFFLINE_QUERY,
		{
			variables: {
				profession_slug: `%${professionFilter || ''}%`,
				city_slug: `%${cityFilter || ''}%`,
				now: dayjs().startOf('day').toISOString()
			}
		}
	);

	const [formKey, setFormKey] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalFilter, setModalFilter] = useState('');
	const [showSpecializationsButton, setShowSpecializationsButton] =
		useState(false);

	useEffect(() => {
    setFormKey(formKey + 1);
  }, [
    professionFilter,
    router.query.slugPath,
    router.query.slugPath?.at(0),
    router.query.slugPath?.at(1),
  ]);

	useEffect(() => {
		setModalOpen(false);
	}, [router.query[QueryParamsMapEnum.Profession]]);

	const parentProfession = data
		? data.professions.find((profession: ProfessionDto) => {
				return (
					profession.name.toLowerCase() ===
					professionFilter?.toString().toLowerCase()
				);
		  })
		: null;

	const professionId = parentProfession ? parentProfession.id : 0;

	const isScrollable = () => {
		const element = document.getElementById(SPECIALIZATIONS_ID) as HTMLElement;
		// Compare the height to see if the element has scrollable content
		const hasScrollableContent = element.scrollWidth > element.clientWidth;

		// It's not enough because the element's `overflow-y` style can be set as
		// * `hidden`
		// * `hidden !important`
		// In those cases, the scrollbar isn't shown
		const overflowXStyle = window.getComputedStyle(element).overflowX;
		const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;

		return hasScrollableContent && !isOverflowHidden;
	};

	useEffect(() => {
		setShowSpecializationsButton(isScrollable());
	});

	const createSpecializationLinks = createSpecializationLinksCreator(
		data,
		professionId,
		cityFilter,
		onlineFilter,
		createPrettySearchResultUrl
	);

	const searchLabel = [
		router.query[QueryParamsMapEnum.Profession]?.toString().replace(/\-/g, ' '),
		router.query[QueryParamsMapEnum.City]
	]
		.filter((v) => !!v)
		.join(', ');

	return (
    <SessionProvider userSession={userSession}>
      <Head>
        <title>Specjaliści - znany.pl</title>
        <meta
          name="keywords"
          content={["specjaliści", professionFilter, cityFilter, onlineFilter]
            .filter((item) => !!item)
            .join(",")}
        />
        <meta
          name="description"
          content={
            [professionFilter, cityFilter, onlineFilter]
              .filter((item) => !!item)
              .join(",") + " - Specjaliści na portalu znany.pl"
          }
        />
      </Head>
      <MainWrapper>
        <MainHeader />
        <div className="border-t border-b md:sticky z-20 top-0 bg-white px-2">
          <div className={`mx-auto md:w-11/12 xl:w-10/12`}>
            <div className={`lg:w-11/12 xl:w-10/12 py-3 pt-16 lg:pt-3`}>
              <HomeForm
                searchType="specialist"
                key={formKey}
                filters={{
                  city: cityFilter?.toString() || "",
                  profession: professionFilter?.toString() || "",
                  online: onlineFilter,
                }}
                data={data}
                createSearchResultUrl={createPrettySearchResultUrl}
              />
            </div>
          </div>
        </div>
        <PageFullWrapper bg={` bg-gray-100`}>
          {/*BREAKER*/}
          <div className={`mx-auto md:w-11/12 xl:w-10/12`}>
            {/*CATEGORIES*/}
            <div className="flex items-center justify-between">
              <div
                id={SPECIALIZATIONS_ID}
                className={`gap-4 rounded flex-grow flex items-center flex-nowrap p-2 text-sm text-gray-500 overflow-x-auto customscroll`}
              >
                {createSpecializationLinks()}
              </div>
              {showSpecializationsButton && (
                <div className="ml-auto pb-2">
                  <div
                    color="primary"
                    className="ml-4 flex items-center text-blue-600"
                    onClick={() => setModalOpen(true)}
                  >
                    <a className="hover:underline cursor-pointer text-sm font-bold">
                      więcej
                    </a>
                    <GoChevronRight />
                  </div>
                </div>
              )}
            </div>
            {modalOpen && (
              <Modal
                isOpen
                className="back-office overflow-y-hidden mobile-fullscreen lg:w-2/5"
              >
                <ModalHeader toggle={() => setModalOpen(false)}>
                  <div className="ml-4">
                    {capitalize(
                      router.query[QueryParamsMapEnum.Profession] as string
                    )}{" "}
                    - specjalizacje
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className={`mb-2`}>
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Szukaj specjalizacji"
                      onChange={(e) => setModalFilter(e.target.value)}
                    />
                  </div>
                  <div className={`justify-center items-center`}>
                    {data &&
                      data.professions &&
                      filterProfessionByParentId(
                        data?.professions,
                        professionId
                      )
                        .filter((p) =>
                          !!modalFilter
                            ? p.name
                                .toLowerCase()
                                .includes(modalFilter.toLowerCase())
                            : true
                        )
                        .map((p) => (
                          <Link
                            href={createSearchResultUrl(
                              p.slug,
                              cityFilter?.toString(),
                              onlineFilter
                            )}
                          >
                            <a
                              className={`flex justify-between items-center hover:underline text-center p-1 py-2`}
                            >
                              <div className={`w-max`}>{p.name}</div>
                              <BsChevronRight />
                            </a>
                          </Link>
                        ))}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="ml-auto"
                    onClick={() => setModalOpen(false)}
                  >
                    Zamknij
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {Boolean(searchLabel) && (
              <div className="pt-5 -mb-1 text-xl ">
                Wyniki wyszukiwania dla &quot;
                {searchLabel}
                &quot;
              </div>
            )}

            <div className={`mt-4 grid grid-cols-3 gap-4`}>
              <div className={`col-span-3 md:col-span-2`}>
                {data?.specialists.length > 0 ? (
                  data.specialists.map((specialist: any, i: Number) =>
                    specialist ? (
                      <SpecialistCard
                        key={`${i}`}
                        specialist={specialist}
                        cityFilter={cityFilter?.toString()}
                        onlineFilter={onlineFilter}
                      />
                    ) : null
                  )
                ) : !loading ? (
                  <NoResultsBanner />
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}

                {/* {data?.specialists.length ? <Pagination /> : null} */}
              </div>

              <div className={`w-full h-full hidden md:grid`}>
                {data?.specialists.length ? (
                  <SpecialistsMap
                    cityFilter={cityFilter?.toString()}
                    specialists={data?.specialists}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </PageFullWrapper>
        <Footer />
      </MainWrapper>
    </SessionProvider>
  );
};
export default Specjalisci;
